@import "~@mdi/font/css/materialdesignicons.min.css"
@import "~bootstrap/dist/css/bootstrap.min.css"
@import "vars"
*
  font-family: 'Montserrat', sans-serif
  color: $color_black

.app
  min-height: 100vh
  display: flex
  flex-direction: column
  background: $color_white

  &__content
    flex-grow: 1

  a
    text-decoration: none

body
  background: #FFFFFF

.header
  height: 100px
  display: flex
  padding: 0 20px
  justify-content: space-between
  align-items: center
  background: $color_dark_blue
  margin-bottom: 25px

.footer
  background: #4A4A4A
  padding: 65px 0 45px 0
  margin-top: 75px

  &__wrap-logo
    display: flex
    align-items: center
    justify-content: center

  &__logo
    width: 280px

  &__title
    font-weight: 500
    font-size: 20px
    line-height: 24px
    color: $color_white

  &__desc
    font-size: 14px
    line-height: 26px
    color: $color_white

  &__additionally
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    margin-top: 120px

  &__contacts &__additionally-phone
    font-weight: bold
    font-size: 14px
    line-height: 24px
    color: $color_white

  &__contacts &__additionally-desc-phone
    font-size: 14px
    line-height: 24px
    color: $color_white

  &__contacts
    display: flex
    flex-direction: column
    font-size: 14px
    line-height: 24px
    color: $color_white
    text-align: center

  &__links
    margin-top: 45px

    & > a
      font-weight: 500
      font-size: 14px
      line-height: 24px
      color: $color_white

      &:after
        content: " |"

      &:nth-last-child:after
        content: ""

.title
  font-weight: bold
  font-size: 40px
  text-align: center
  color: $color_dark_blue
  margin-bottom: 26px

.btn-blue
  color: #fff
  background: $color_blue
  border-color: $color_blue
  font-size: 16px
  font-weight: 500
  min-height: 45px
  padding-top: 10px

  &:hover
    transition: 0.5s
    color: #fff
    background: darken($color_blue, 10%)

.search-doctor
  margin-top: 42px

  &__tabs
    height: 43px
    display: flex

  &__tab
    color: $color_white
    display: block
    background: #6197BC
    font-size: 14px
    padding: 11px 8px 8px 8px
    border-radius: 5px 5px 0 0
    margin-right: 2px
    min-width: 216px
    text-align: center
    min-height: 58px
    cursor: pointer

    &.active
      cursor: auto
      background: $color_dark_blue

  &__content
    height: 95px
    display: flex
    align-items: center
    position: relative
    padding: 0 16px 3px 16px
    background: $color_dark_blue

  &__input-marker
    position: absolute
    font-size: 24px
    left: 24px
    color: #999999

  &__input
    width: 242px
    height: 45px
    margin-right: 13px
    border: 1px solid #CCCCCC
    border-radius: 4px
    font-size: 14px
    padding: 0 20px 0 35px

.news
  margin-bottom: 23px

  &__new
    margin-top: 10px
    text-align: center
    padding: 18px 0 15px 0
    background: #FFF0A2
    border: 1px solid #FFE24D

.block-map
  &__map
    width: 100%
    height: 320px

  &__title
    font-size: 20px
    font-weight: 500
    margin-bottom: 20px

  &__desc
    font-size: 14px

.office-card
  background: #F4F4F4
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.149274)
  border-radius: 5px
  padding: 11px 10px 16px 10px

  &__header
    display: flex
    align-items: center
    margin-bottom: 11px

  &__header &__marker
    font-size: 24px
    color: $color_black
    margin-right: 14px

  &__header &__title
    font-size: 18px
    font-weight: 500
    margin-bottom: 0

  &__body
    display: flex

  &__body &__img
    width: 100%
    max-width: 100px
    height: auto
    margin-right: 12px

  &__body &__address
    font-size: 14px
    line-height: 23px
    color: #1F1F1F

  &__body &__phone
    font-size: 14px
    line-height: 23px
    color: #004677

  &__body &__actions
    margin-left: auto

  &__body &__actions &__btn
    font-weight: 500
    font-size: 14px
    line-height: 17px
    background: #004677
    border-radius: 5px
    min-height: 40px
    margin-bottom: 10px
    display: flex
    align-items: center

    span
      color: $color_white

    .icon
      font-size: 24px
      margin-right: 7px
      color: $color_white

  &__body &__actions &__website
    font-weight: 500
    font-size: 14px
    line-height: 17px
    text-decoration-line: underline
    display: flex
    align-items: center
    margin-left: 15px

    *
      color: #004677

    .icon
      font-size: 24px
      margin-right: 4px

  &__footer
    display: flex
    align-items: center

  &__footer &__rating
    display: flex
    align-items: center
    padding-left: 15px

  &__footer &__rating &__stars
    display: flex
    margin-left: 10px
    margin-right: 15px

  &__footer &__rating &__rating-value
    font-weight: bold
    font-size: 24px
    line-height: 29px
    text-align: center
    color: #3D3D3D

  &__footer &__distance
    width: 100px
    font-weight: 500
    font-size: 12px
    line-height: 15px
    text-align: center

.start
  font-size: 24px
  color: #b3b3b3

  &.active
    color: #F5A623